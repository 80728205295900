// components
import { MainNav } from './mainnav/main-nav';
import { MetaNav } from './metanav/meta-nav';

// styles
import { NavFlyoutWrapper, TransparentBackground } from './nav-flyout.styles';

// utils
import { useMegaMenuRefs, useNavOpen } from './mega-menu-provider';
import { useToggleChatWidgetButtons } from './utils/chat-widget-buttons';

export function NavFlyout() {
  const { isNavOpen, setIsNavOpen } = useNavOpen();
  const { navFlyoutWrapperRef } = useMegaMenuRefs();
  useToggleChatWidgetButtons();

  return (
    <>
      <TransparentBackground isNavOpen={isNavOpen} onClick={() => setIsNavOpen(false)} />
      <NavFlyoutWrapper ref={navFlyoutWrapperRef}>
        <MainNav />
        <MetaNav />
      </NavFlyoutWrapper>
    </>
  );
}
