import produce from 'immer';
import { Reducer } from 'redux';
import { EventsAction } from './actions';
import { EventsState } from './reducer.types';

const initialState = { mostCurrent: {} };

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<EventsState, EventsAction> = produce(
  (draft: EventsState, action: EventsAction) => {
    switch (action.type) {
      case 'EVENTS_REQUEST_PENDING':
        draft.pending = true;
        break;

      case 'EVENTS_REQUEST_FULFILLED':
        draft.pending = false;
        draft[action.language] = action.events;
        break;

      case 'EVENTS_REQUEST_REJECTED':
        draft.pending = false;
        draft.error = action.error;
        break;

      case 'EVENTS_MOSTCURRENT_REQUEST_FULFILLED':
        draft.pending = false;
        draft.mostCurrent[action.language] = action.events;
        break;

      default:
    }
  },
  initialState,
);

export default reducer;
