import { SyntheticEvent } from 'react';

type CommonProps = {
  url: string;
  external?: boolean;
  window?: string;
};

export type NavItem = CommonProps & {
  label: string;
  children?: NavItems;
};

type GDDSMenuItem = CommonProps & {
  title: string;
  items?: GDDSMenuItem[];
  disabled?: boolean;
  selected?: boolean;
  onClick?: (event: SyntheticEvent, hasSubItems: boolean) => void;
  withoutIcon?: boolean;
};

export type NavItems = NavItem[];
type GDDSMenuItems = GDDSMenuItem[];

const hasLabel = (subItem: NavItem): boolean => Boolean(subItem.label);

export function transformNavItemsRawFactory(
  navClick: (item: NavItem) => (event: any, hasSubItems: boolean) => void,
  toOverviewTranslation: string,
) {
  function transformNavItemsRaw(navItems: NavItems): GDDSMenuItems {
    return navItems?.map((e) => {
      const items = e.children?.filter(hasLabel) ?? [];
      if (e.url) {
        items?.unshift({ label: toOverviewTranslation, url: e.url });
      }
      return {
        ...e,
        title: e.label,
        onClick: navClick(e),
        items: e.children ? transformNavItemsRaw(items) : undefined,
      };
    });
  }

  return transformNavItemsRaw;
}
