import styled, { css } from 'styled-components';

// utils
import { media } from '../utils/no-touch-device';

export const MetaNavContainerDesktop = styled.div<{
  showOnlyIcons: boolean;
  isOverflowHidden: boolean;
}>`
  ${({ showOnlyIcons, isOverflowHidden, theme }) => {
    return css`
      display: none;

      @media ${theme.device.large} {
        display: flex;
        flex-direction: row;
        margin-right: 1.5rem;
        overflow: ${isOverflowHidden && showOnlyIcons ? 'hidden' : 'visible'};
        visibility: ${showOnlyIcons ? 'visible' : 'hidden'};
        width: ${!showOnlyIcons && 0};
        opacity: ${showOnlyIcons ? 1 : 0};
        transition: opacity var(--meta-nav-animation-duration) ease;
      }
    `;
  }}
`;

export const MetaNavLinks = styled.ul(({ theme }) => {
  return css`
    max-width: 36rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    padding-left: 0;

    && > li {
      flex-basis: content;
      min-width: 0;
      max-width: 10rem;
      margin-left: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        min-height: unset;
        display: block;
        padding: 0.6875rem 0.5rem;
        font-weight: ${theme.fontWeights.regular};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        ${media.noTouch`
          padding: 0.5625rem 0.5rem;
        `}
      }
    }
  `;
});
