import styled, { css } from 'styled-components';
import { DesktopMainNavContainer } from '../common.styles';

export const ThirdLevelNavDesktop = styled.div(({ theme }) => {
  return css`
    position: absolute;
    right: 0;
    top: 0;
    width: 66.66%;
    padding: 1rem 0 1.5rem 1.5rem;
    background-color: ${theme.colors.white};
    ${DesktopMainNavContainer} {
      &.pictogram {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media ${theme.device.xlarge} {
          row-gap: 1.5rem;
        }
      }
    }
  `;
});

export const TitleWrapper = styled.div`
  margin-top: 0.625rem;
  margin-bottom: 1.125rem;
`;
