import { useEffect } from 'react';

// gdds comonents
import { Divider, Container } from '@geberit/gdds';

// components
import { LocatorSwitch } from './locator-switch';
import { NavButton } from '../common/nav-button';
import { NavItem } from '../common/nav-item';

// styles
import { BackButton, HorizontalMarginWrapper, AnimationLayer } from '../common/common.styles';
import { FlyoutWrapper, Wrapper } from './locator-selector.styles';

// utils
import { useHeaderPin } from 'utils/hooks/use-header-pin';
import { useLocatorPageOpen, useNavOpen } from '../mega-menu-provider';
import { decodingContent } from 'utils/decodingContent';
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { useAnimate } from '../hooks/use-animate';

export function LocatorSelector() {
  const { setIsLocatorPageOpen } = useLocatorPageOpen();
  const { setIsNavOpen } = useNavOpen();
  const { enabled, entries = [] } = useHeaderPin();
  const locatorConfig = useLocatorConfiguration();
  const showButton = entries.length > 1;
  const showLink = entries.length === 1;

  const { closeFlyout, openFlyout, flyoutOpen, animationType, onAnimationEnd } = useAnimate();

  const show = enabled && entries.length > 0;
  const label = decodingContent(locatorConfig.urlSettings?.label);
  const ariaLabel = decodingContent(locatorConfig.urlSettings?.ariaLabel);

  useEffect(() => {
    setIsLocatorPageOpen(flyoutOpen);
  }, [flyoutOpen, setIsLocatorPageOpen]);

  return (
    <Wrapper show={show}>
      {showButton && (
        <NavButton
          className="trigger"
          leftIconName="location"
          rightIconName="chevronRight"
          onClick={openFlyout}
          aria-label={ariaLabel}
        >
          {label}
        </NavButton>
      )}
      {showLink && (
        <NavItem
          className="trigger"
          leftIconName={'location'}
          onClick={() => {
            setIsNavOpen(false);
          }}
          href={entries[0].url || ''}
          aria-label={entries[0]?.ariaLabel}
        >
          <span className="label">{entries[0].label}</span>
        </NavItem>
      )}
      <HorizontalMarginWrapper>
        <Divider />
      </HorizontalMarginWrapper>
      {entries.length > 0 && flyoutOpen && (
        <FlyoutWrapper>
          <AnimationLayer animationType={animationType} onAnimationEnd={onAnimationEnd}>
            <Container maxContentWidth="80rem">
              {
                <BackButton leftIconName="chevronLeft" onClick={closeFlyout}>
                  {label}
                </BackButton>
              }
              <LocatorSwitch items={entries} />
            </Container>
          </AnimationLayer>
        </FlyoutWrapper>
      )}
    </Wrapper>
  );
}
