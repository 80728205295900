import { MouseEvent, useId } from 'react';

// components
import { NavItem } from './nav-item';

// utils
import { handlePopup } from 'utils/openInPopup';
import { useNavOpen } from '../mega-menu-provider';
import { metaNavigationTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';

export function NavItemWrapper({
  item,
  onClick,
  as,
  ref,
  ...restProps
}: { item: Link } & React.HTMLProps<HTMLAnchorElement>) {
  const windowId = useId();
  const { setIsNavOpen } = useNavOpen();
  const track = useTracking();

  function handleOnClick(e: MouseEvent<HTMLAnchorElement>) {
    setIsNavOpen(false);
    if (typeof onClick === 'function') {
      onClick(e);
    }
    if (item.window === '_popup') {
      e.preventDefault();
      handlePopup(item, windowId);
    }
    track.trackEvent(metaNavigationTrackingAction(item.text));
  }

  return (
    <NavItem
      href={item.target || ''}
      target={getTarget(item)}
      onClick={handleOnClick}
      {...restProps}
    >
      {item.text}
    </NavItem>
  );
}

const getTarget = (item: Link) => {
  const target = item.window ? item.window : '_self';
  if (item.type === 'external_link') {
    return target === 'default' || target === '_popup' ? '_blank' : target;
  }

  return target === 'default' || target === '_popup' ? '_self' : target;
};
