import { css } from 'styled-components';

export const media = {
  touch: (...args: Parameters<typeof css>) => css`
    @media (hover: none) and (pointer: coarse) {
      ${css(...args)};
    }
  `,
  noTouch: (...args: Parameters<typeof css>) => css`
    @media (hover: hover) and (pointer: fine) {
      ${css(...args)};
    }
  `,
  hover: (...args: Parameters<typeof css>) => media.noTouch`
    :hover {
      -webkit-tap-highlight-color: transparent;
      ${css(...args)};
    }
  `,
};
