import styled, { css } from 'styled-components';
import { Container as GridContainer } from '@geberit/gdds';

import { media } from 'mm/utils/no-touch-device';
import { GridCol } from 'mm/common/common.styles';

export const DesktopMainNavContainer = styled.ul(({ theme }) => {
  return css`
    padding: 0;
    margin: 0;
  `;
});

export const MainNavDesktopContainer = styled.ul(({ theme }) => {
  return css`
    display: none;

    @media ${theme.device.large} {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
      column-gap: 2rem;

      > li {
        flex-basis: content;
      }
    }
  `;
});

export const DesktopFlyout = styled.div<{ show: boolean; visible: boolean }>(
  ({ theme, show, visible }) => {
    return css`
      display: none;
      grid-template-rows: ${show ? '1fr' : '0fr'};
      transition-property: grid-template-rows;
      transition-duration: var(--animation-duration);
      transition-delay: ${show ? '0s' : 'var(--animation-duration)'};
      border-top-width: ${show ? '1px' : '0px'};
      border-top-style: solid;
      border-top-color: ${theme.colors.cl08};

      @media ${theme.device.large} {
        display: grid;
        visibility: ${visible ? 'visible' : 'hidden'};
        position: absolute;
        left: 0;
        right: 0;
        top: 'var(--first-level-nav-height, 56px)';
        width: 100%;

        ${GridContainer} {
          position: relative;
          background-color: white;
        }
      }
    `;
  },
);

export const FlyoutInner = styled.div(({ theme }) => {
  return css`
    overflow: hidden;
    display: flex;

    ${GridContainer} {
      height: 25.5rem;
      max-height: 25.5rem;
      max-width: 1232px;
      display: flex;
      flex: 1 0 calc(100% - 3rem);
      position: relative;
      padding: 0;

      ${media.touch`
          height: 27.75rem;
          max-height: 27.75rem;
        `}
    }

    ${GridCol} {
      :first-child {
        background-color: ${theme.colors.grey03};
        padding-right: 1.5rem;
      }

      :last-child {
        background-color: ${theme.colors.white};
        padding-left: 1.5rem;
      }
    }
  `;
});
