import { Typo } from '@geberit/gdds';
import styled, { css } from 'styled-components';
import { HorizontalMarginWrapper } from '../common/common.styles';
import { ProfileFlyoutWrapper } from '../profile/profile-flyout.styles';
import { media } from '../utils/no-touch-device';

export const Wrapper = styled.div<{ show: boolean }>(
  ({ show }) => css`
    ${HorizontalMarginWrapper}, .trigger {
      display: ${show ? 'flex' : 'none'};

      @media (width >= 375px) {
        display: none;
      }
    }
  `,
);

export const FlyoutWrapper = styled.div<{ isOpen?: boolean }>`
  ${({ theme, isOpen = true }) => {
    return css`
      display: ${isOpen ? 'initial' : 'none'};
      position: fixed;
      margin-top: 4.25rem;
      padding: 1.5rem 0rem 1.5rem 0rem;
      height: 100vh;
      inset: 0;
      background-color: ${theme.colors.white};
      z-index: 22;

      ${media.noTouch`
        margin-top: 4rem;
      `}

      @media ${theme.device.medium} {
        display: initial;
        top: 0;
        right: 0;
        left: ${isOpen ? 'calc(100% - 21rem)' : '100%'};
        width: 21rem;
        height: var(--flyout-height);
        margin-top: var(--flyout-margin-top);
        transition: left 0.3s ease;
        padding-top: 1.5rem;

        ${ProfileFlyoutWrapper} {
          box-shadow: unset;
          position: unset;
          padding: 0;
          opacity: ${isOpen ? 1 : 0};
        }
      }
    `;
  }}
`;

export const StyledTypo = styled((p) => <Typo {...p} />)`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey13};
`;
