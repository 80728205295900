import { MetaNavigationDesktop } from '@geberit/gdds';

// components
import { SearchIndexOff } from 'components/SearchIndex/SearchIndexOff';
import NavigationRestriction from 'components/Navigation/NavigationRestriction';
import MainNavigation from 'components/Navigation/MainNavigation/MainNavigation';
import GddsMainNavigation from 'components/Navigation/MainNavigation/gdds-main-navigation';
import { AnimatedLogo } from 'components/Logo/animated-logo';

// hooks
import useTouchDetector from 'utils/hooks/use-touch-detector';
import useIsCampus from '../../utils/use-is-campus';
import { useThemeName } from 'utils/hooks/use-theme';
import { useGroupMetaNaviData } from '../../utils/use-metanav';
import { useSelector } from 'react-redux';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useClientSideRender } from 'utils/hooks/use-client-side-render';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes, themeToGridSize } from 'utils/gridSize';
import { navigationMetaPreviewIdSelector } from 'utils/selectors/navigationSelectors';

// styles
import styles from './header.module.scss';

function GroupHeader() {
  const isTouch = useTouchDetector();
  const isCampus = useIsCampus();
  const isDesktop = useIsDesktop();
  const isClient = useClientSideRender();
  const metanaviData = useGroupMetaNaviData(isDesktop);
  const themeName = useThemeName();

  const metaNavigationPreviewId = useSelector(navigationMetaPreviewIdSelector);

  return (
    <SearchIndexOff>
      <div
        id="page-header"
        className={classNameBuilder(
          styles.pageHeader,
          isTouch && styles.isTouch,
          isTouch && 'isTouch',
          isDesktop && !isTouch ? 'pageHeader--full' : 'pageHeader--reduced',
          'pageHeader--meta-nav',
          'group',
        )}
      >
        {isTouch || !isDesktop ? (
          <MainNavigation isCampus={isCampus} isDesktop={isDesktop} />
        ) : (
          <>
            <div className={styles.gddsMetaNavi}>
              <MetaNavigationDesktop
                previewId={metaNavigationPreviewId}
                dataEditType="meta_navigation"
                maxContainerWidth={buildSize(gridSizes[themeToGridSize[themeName]])}
                {...metanaviData}
                icons={metanaviData?.secondIcons?.filter?.(Boolean)}
              />
            </div>
            <GddsMainNavigation />
          </>
        )}
        {isClient && <AnimatedLogo />}
      </div>
      <NavigationRestriction />
    </SearchIndexOff>
  );
}

export default GroupHeader;
