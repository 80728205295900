import { useSelector } from 'react-redux';
import { isCCSelector } from '../selectors/environmentSelectors';
const selectFn =
  process.env.JEST_WORKER_ID !== undefined
    ? (selector: (_: {}) => boolean) => selector({})
    : useSelector;

export function useIsCC() {
  const isCC = selectFn(isCCSelector);
  return isCC;
}
