import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import key from 'weak-key';

// components
import { NavItem } from '../common/nav-item';
import { EditWrapper } from 'components/ContentCreator/EditWrapper';

// styles
import { MetaNavLinks, MetaNavContainerDesktop } from './meta-navigation-desktop.styles';
import { NavItemContainer } from '../common/common.styles';

// utils
import { decodingContent } from 'utils/decodingContent';
import {
  navigationMetaItemsSelector,
  navigationMetaPreviewIdSelector,
} from 'utils/selectors/navigationSelectors';
import { useDesktopFlyoutOpen } from '../mega-menu-provider';
import { metaNavigationTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';

type MetaNavigationDesktopProps = {
  showOnlyIcons: boolean;
};

export function MetaNavigationDesktop({ showOnlyIcons }: Readonly<MetaNavigationDesktopProps>) {
  const metaNavigationItems = useSelector(navigationMetaItemsSelector);
  const { setIsDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const track = useTracking();
  const metaNavigationPreviewId = useSelector(navigationMetaPreviewIdSelector);
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);

  useEffect(() => {
    if (!showOnlyIcons) {
      setIsOverflowHidden(true);
    }
  }, [showOnlyIcons]);

  return (
    <MetaNavContainerDesktop
      showOnlyIcons={showOnlyIcons}
      onTransitionEnd={() => showOnlyIcons && setIsOverflowHidden(false)}
      isOverflowHidden={isOverflowHidden}
    >

      <EditWrapper previewId={metaNavigationPreviewId} editType="meta_navigation">
        <MetaNavLinks>
          {metaNavigationItems?.map((metaNavItem, index) => {
            return (
              <NavItemContainer key={key(metaNavItem)} className="with-border">
                <NavItem
                  onClick={() => {
                    setIsDesktopFlyoutOpen(false);
                    track.trackEvent(metaNavigationTrackingAction(metaNavItem.text));
                  }}
                  href={metaNavItem.target || ''}
                  aria-label={metaNavItem?.ariaLabel}
                >
                  {decodingContent(metaNavItem.text)}
                </NavItem>
              </NavItemContainer>
            );
          })}
        </MetaNavLinks>
      </EditWrapper>
    </MetaNavContainerDesktop>
  );
}
