import { useReducer, createContext } from 'react';
import produce from 'immer';

// utils
import { useContextValue } from 'utils/hooks/use-context-value';

export const ADD_NAVIGATION_RESTRICTION = 'preview/ADD_NAVIGATION_RESTRICTION';
export const RESET_NAVIGATION_RESTRICTION = 'preview/RESET_NAVIGATION_RESTRICTION';

interface State {
  navigationRestrictions: any;
}

const initialState = { navigationRestrictions: null };

export const PreviewContext = createContext<{ state: State; dispatch: React.Dispatch<any> }>({
  state: initialState,
  dispatch: () => null,
});
const previewReducer = produce((draft, action) => {
  switch (action.type) {
    case RESET_NAVIGATION_RESTRICTION:
      draft.navigationRestrictions = null;
      break;
    case ADD_NAVIGATION_RESTRICTION:
      if (!draft.navigationRestrictions) {
        draft.navigationRestrictions = [];
      }
      draft.navigationRestrictions.push(action.entry);
      break;

    default:
  }
}, initialState);

export function PreviewContextProvider({ children }: Readonly<React.PropsWithChildren>) {
  const reducer = useReducer(previewReducer, initialState);
  const value = useContextValue(reducer);

  return <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>;
}
