import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// styles
import { LogoWrapper, StyledContainer } from './Logo.styles';

// types
import type { Props, AnimatedLogoProps, LogoStateProps } from './types';

// utils
import { useGroup } from 'utils/hooks/use-group';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { useSearchOpen } from 'features/header/components/header/mega-menu/mega-menu-provider';
import { globalsSelector, showMegaMenuSelector } from 'utils/selectors/globalsSelectors';
import { useGdds } from 'utils/hooks/use-gdds';
import { buildSize, gridSizes } from 'utils/gridSize';

export function AnimatedLogo({
  shadowDisabled = false,
  sticky = false,
  className,
}: Readonly<Props>) {
  const animatedLogo: AnimatedLogoProps = useSelector(globalsSelector)?.['animatedLogos'];
  const showMegaMenu = useSelector(showMegaMenuSelector);
  const { isSearchOpen } = useSearchOpen();
  const [logo, setLogo] = useState<LogoStateProps>({
    src: undefined,
    altText: '',
  });
  const isGroup = useGroup();
  const isGdds = useGdds();
  const isEnabled = animatedLogo?.enableAnimatedLogos === 'enabled';
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isTabletMegaMenu = useIsTablet({ gdds: true });
  const isMobile = useIsMobile();
  const logoRef = useRef<HTMLAnchorElement | null>(null);
  const { trackClick } = useTracking2();
  const megaMenuEnabled = showMegaMenu?.enabled;

  let maxContentWidth;

  if (isGroup) {
    maxContentWidth = '78rem';
  } else if (isGdds) {
    maxContentWidth = buildSize(gridSizes.gddsFullGrid);
  } else {
    maxContentWidth = '80rem';
  }

  useEffect(() => {
    const getPositionRight = () => {
      if (isTablet || isMobile || (megaMenuEnabled && isTabletMegaMenu)) {
        return 0;
      }

      return 24;
    };

    const currentLogo = logoRef.current;

    if (currentLogo) {
      if (parent && !isSearchOpen) {
        currentLogo.style.right = `${
          isTablet || isMobile || (megaMenuEnabled && isTabletMegaMenu) ? 0 : getPositionRight()
        }px`;
      } else if (isSearchOpen) {
        currentLogo.style.right = '';
      }
    }
  }, [megaMenuEnabled, isTabletMegaMenu, isMobile, isTablet, isSearchOpen]);

  useEffect(() => {
    if (isDesktop) {
      setLogo({ src: animatedLogo?.animatedLogoLarge, altText: animatedLogo?.altTextLarge });
    }

    if (isTablet || (megaMenuEnabled && isTabletMegaMenu)) {
      setLogo({ src: animatedLogo?.animatedLogoMedium, altText: animatedLogo?.altTextMedium });
    }

    if (isMobile) {
      setLogo({ src: animatedLogo?.animatedLogoSmall, altText: animatedLogo?.altTextSmall });
    }
  }, [megaMenuEnabled, isTabletMegaMenu, isMobile, isDesktop, isTablet, animatedLogo]);

  const showAnimatedLogo = !isSearchOpen && isEnabled && typeof window !== 'undefined';

  return showAnimatedLogo ? (
    <StyledContainer maxContentWidth={maxContentWidth} className="logo-wrapper">
      <LogoWrapper
        className={className}
        href={animatedLogo?.linkTarget}
        ref={logoRef}
        target="_blank"
        onClick={() => {
          trackClick({
            click_intent: '150yr logo',
            click_element: '150yr logo',
            click_url: animatedLogo?.linkTarget,
          });
        }}
        isSearchOpen={isSearchOpen}
        sticky={sticky}
      >
        <img src={logo.src} alt={logo.altText} />
        {!shadowDisabled && <div className="shadow"></div>}
      </LogoWrapper>
    </StyledContainer>
  ) : null;
}
