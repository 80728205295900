import { useEffect } from 'react';

export function useKeypress(key: string, callback: () => void) {
  useEffect(() => {
    const onKeyup = (e: KeyboardEvent) => {
      if (e.key === key) callback();
    };
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [key, callback]);
}

export function useOnEscapePress(callback) {
  useKeypress('Escape', callback);
}

export function useOnArrowDownPress(callback) {
  useKeypress('ArrowDown', callback);
}

export function useOnArrowUpPress(callback) {
  useKeypress('ArrowUp', callback);
}