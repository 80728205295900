import { Typo } from '@geberit/gdds';

// styles
import { StyledSpan } from './initials.styles';

interface InitialsProps {
  initials: string;
}

export function Initials({ initials }: Readonly<InitialsProps>) {
  return (
    <StyledSpan>
      <Typo variant="label1">{initials}</Typo>
    </StyledSpan>
  );
}
