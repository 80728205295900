import { useCallback, useEffect, useState } from 'react';

// utils
import { useNavOpen } from '../mega-menu-provider';

export function useAnimate() {
  const [flyoutOpen, setFlyoutOpen] = useState<boolean>(false);
  const [animationType, setAnimationType] = useState<'fadeIn' | 'fadeOut'>('fadeIn');
  const { isNavOpen } = useNavOpen();

  function openFlyout() {
    setFlyoutOpen(true);
    setAnimationType('fadeIn');
  }

  function closeFlyout() {
    setAnimationType('fadeOut');
  }

  const onAnimationEnd = useCallback(() => {
    if (animationType === 'fadeOut') setFlyoutOpen(false);
  }, [animationType]);

  useEffect(() => {
    if (flyoutOpen) {
      setAnimationType('fadeIn');
    }
  }, [flyoutOpen]);

  useEffect(() => {
    if (!isNavOpen) {
      setAnimationType('fadeOut');
    }
  }, [isNavOpen]);

  return {
    openFlyout,
    closeFlyout,
    flyoutOpen,
    animationType,
    onAnimationEnd,
  };
}
