import getConfig from '../config';

export function getEndpoint(endpoint: string, state): string {
  if ('getState' in state && typeof state.getState === 'function') {
    state = state.getState();
  }
  const appConfig = getConfig({
    ...state?.globals?.server,
    ...(typeof window !== 'undefined' ? { baseUrl: '' } : {}),
  });

  return appConfig.endpoints[endpoint].path;
}

export default getEndpoint;
