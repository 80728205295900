import styled, { css } from 'styled-components';
import { useMainNavShown, useNavOpen, useSearchOpen } from './mega-menu-provider';

export const TransparentBackground = styled.div<{ isNavOpen: boolean }>`
  ${({ theme, isNavOpen }) => {
    return css`
      height: var(--flyout-height);
      margin-top: var(--flyout-margin-top);
      background-color: rgba(${theme.colors.transparentBlack}, ${isNavOpen ? 0.5 : 0});
      transition: background-color 0.3s ease;
      width: 100%;
      z-index: 98;
      display: none;
      position: fixed;
      pointer-events: ${isNavOpen ? 'all' : 'none'};

      @media ${theme.device.medium} {
        display: initial;
      }
      @media ${theme.device.large} {
        display: none;
      }
    `;
  }}
`;

export const NavFlyoutWrapper = styled.div`
  ${({ theme }) => {
    const { isSearchOpen, isSearchClosedEnd } = useSearchOpen();
    const { isMainNavShown } = useMainNavShown();
    const { isNavOpen } = useNavOpen();

    return css`
      height: ${isNavOpen ? 'var(--flyout-height)' : 'var(--flyout-margin-top)'};
      padding-top: var(--flyout-margin-top);
      transition: height 0.3s ease;
      transition-delay: ${isNavOpen ? '0s' : '0.3s'};
      width: 100%;
      background-color: ${theme.colors.cl01};
      position: fixed;
      z-index: 98;
      display: flex;
      flex-direction: column;
      padding-bottom: var(--safe-area-inset-bottom);
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
      clip-path inset(0 0 -12px 0);
      overflow: hidden auto;

      @media ${theme.device.medium} {
        width: 21rem;
        right: ${isNavOpen ? 0 : '-21rem'};
        transition: right 0.3s ease;
        ${!isNavOpen && 'transition-delay: var(--animation-duration)'};
        box-shadow: none;
      }

      @media ${theme.device.large} {
        padding-bottom: 0;
        padding-top: 0;
        right: 0;
        margin-top: 0;
        z-index: 97;
        width: 100%;
        height: fit-content;
        max-height: initial;
        ${isSearchOpen && isMainNavShown ? 'position: fixed' : 'position: relative'};
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
        clip-path: ${!isSearchClosedEnd ? 'none' : 'inset(0 0 -12px 0)'};
        overflow: hidden;
      }
    `;
  }}
`;
