import { Icon } from '@geberit/gdds';
import styled, { css } from 'styled-components';

// utils
import { media } from '../utils/no-touch-device';

export const StyledSearch = styled.div<{ show: boolean }>(({ theme, show }) => {
  return css`
    height: 3rem;
    width: ${show ? '100%' : 0};
    visibility: ${show ? 'visible' : 'hidden'};
    opacity: ${show ? 1 : 0};
    transition: all 0.3s ease;
    transition-delay: ${show ? '0.3s' : '0'};
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    margin-top: 2.5rem;

    @media ${theme.device.medium} {
      margin-top: 6.25rem;
    }

    @media ${theme.device.large} {
      margin-top: 0;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      width: 3rem;
      height: 3rem;

      > {
        width: 3rem;
        height: 3rem;
      }

      span {
        align-items: center;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          fill: ${theme.colors.grey12};
        }
      }
    }
  `;
});

export const SearchBar = styled.input<{ focusVisible: boolean }>(({ theme, focusVisible }) => {
  return css`
    border: 0;
    width: 100%;
    height: 3rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    padding-right: 2.5rem;
    padding-left: 0.5rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid ${theme.colors.cl10};
    border-radius: 0px;
    outline: 1px solid transparent;

    :focus {
      ${focusVisible && 'outline: unset;'}
    }

    && {
      ${focusVisible &&
      `border: 1px solid ${theme.colors.primaryLight};
       outline: 1px solid ${theme.colors.primaryLight};`}
    }

    ${media.hover`
      background-color: rgba(${theme.colors.transparentBlack}, 0.04);
      border-bottom-color: ${theme.colors.cl14};
    `}

    :active {
      ${({ theme }) => theme.global.disableTapHighlight}
      background-color: rgba(${theme.colors.transparentBlack}, 0.06);
    }

    ::placeholder {
      line-height: 1.5rem;
      font-size: 1.125rem;
    }

    @media ${theme.device.large} {
      width: 37.5rem;
    }
  `;
});

export const StyledContent = styled.span`
  height: auto;
  display: flex;
  align-items: center;
  white-space: pre;
`;

export const Suggestions = styled.ul<{ left?: number; top?: number; width?: number }>(
  ({ theme, left, top, width }) => {
    return css`
      padding: 0.5rem 0;
      margin: 0;
      top: calc(100% + 2px);
      left: 0;
      width: 100%;
      position: absolute;
      background-color: ${theme.colors.white};
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);

      @media ${theme.device.large} {
        position: fixed;
        left: ${left ?? 0}px;
        top: ${(top ?? 0) + 2}px;
        width: ${width ?? 0}px;
      }
    `;
  },
);

export const SuggestionOption = styled.li(({ theme }) => {
  return css`
    height: 2.75rem;
    padding: 0;
    background-color: ${theme.colors.white};
    list-style: none;

    ${media.noTouch`
      height: 2.5rem;
    `}

    a {
      width: 100%;
      padding: 0.5rem;
      text-decoration: none;
      color: ${theme.colors.black};

      :before {
        content: none;
      }
    }

    &[data-hovered='true'] {
      a {
        background-color: rgba(${theme.colors.transparentBlack}, 0.04);
      }
    }

    ${media.hover`
      a {
        background-color: rgba(${theme.colors.transparentBlack}, 0.04);
      }
    `}
  `;
});

export const SearchIcon = styled(Icon)(({ theme }) => {
  return css`
    position: absolute;
    right: 0.625rem;

    svg {
      width: 1.25rem;
      fill: ${theme.colors.cl12};
    }
  `;
});
