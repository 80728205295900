import styled, { css } from 'styled-components';

// types
import type { AnimationProps } from '../utils/get-flyout-animation-styles';

// utils
import { media } from '../utils/no-touch-device';
import { getFlyoutAnimationStyle } from '../utils/get-flyout-animation-styles';

export const ProfileFlyoutWrapper = styled.div<AnimationProps>`
  ${({ theme }) => {
    return css`
      position: absolute;
      min-width: 12.5rem;
      top: 2.75rem;
      white-space: nowrap;
      background-color: ${theme.colors.cl01};
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.26);
      ${getFlyoutAnimationStyle}

      ${media.noTouch`
        top: 2.5rem;
     `}
    `;
  }}
`;
