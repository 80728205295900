import { useMemo } from 'react';
import key from 'weak-key';

export function useReactKeys<T extends {}>(items: T[], attributes?: (keyof T)[]) {
  return useMemo(() => {
    const generateKey = getKeyGenerator<T>(attributes);

    return items.map((item) => {
      return {
        ...item,
        key: generateKey(item),
      };
    });
  }, [items, attributes]);
}

function getKeyGenerator<T extends {}>(attributes: (keyof T)[] | undefined) {
  return (item: T) => {
    if (!attributes) return key(item);

    return attributes.map((attr) => item?.[attr as string]).join('-');
  };
}
