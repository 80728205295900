import produce from 'immer';
import { Reducer } from 'redux';

import { MaintenanceAction } from './actions';
import { MaintenanceState } from './reducer.types';

const initialState = {
  loading: true,
};

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
export const MaintenanceReducer: Reducer<MaintenanceState, MaintenanceAction> = produce(
  (draft: MaintenanceState, action: MaintenanceAction) => {
    switch (action.type) {
      case 'page/MAINTENANCE_PAGE_SUCCESS':
        draft.content = { ...action.data };
        draft.loading = false;
        break;

      case 'page/MAINTENANCE_PAGE_FAILURE':
        draft.error = action.error;
        draft.loading = false;
        break;

      case 'page/MAINTENANCE_LOADING':
        draft.loading = true;
        break;

      default:
    }
  },
  initialState,
);
