import produce from 'immer';
import isEmpty from 'lodash.isempty';
import { Reducer } from 'redux';
import { SearchAction } from './actions';

import { SearchState } from './reducer.types';

const initialState = {
  params: {},
  isFetching: false,
  error: null,
};

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<SearchState, SearchAction> = produce(
  (draft: SearchState, action: SearchAction) => {
    switch (action.type) {
      case 'search/SEARCH_PENDING':
        draft.isFetching = true;
        break;
      case 'search/SEARCH_FULFILLED':
        draft.isFetching = false;
        break;
      case 'search/SET_SEARCH_QUERY':
        draft.params.q = action.q;
        break;
      case 'search/SET_SEARCH_FILTER':
        draft.params.filter = action.filter;
        break;
      case 'search/SET_SEARCH_OFFSET':
        draft.params.offset = parseInt(action.offset ?? '0');
        break;
      case 'search/SEARCH_RESULTS_REJECTED':
        draft.error = {
          status: action.error.status,
          statusText: action.error.statusText,
        };
        break;
      case 'search/SEARCH_SOFTCONTENT_FULFILLED':
        if (!draft.softContent) {
          draft.softContent = {};
        }
        if (draft.params.offset && draft.params.offset > 0) {
          if (
            draft.softContent.totalResults &&
            action.response.totalResults > draft.softContent.totalResults
          ) {
            draft.softContent.totalResults = action.response.totalResults;
          }

          const ref = !isEmpty(draft.softContent.results) ? draft.softContent.results : [];
          draft.softContent.results = ref?.concat(action.response.results);
        } else {
          draft.softContent = { ...action.response };
        }
        break;
      case 'search/SEARCH_PRODUCTS_FULFILLED':
        if (!draft.softContent) {
          draft.softContent = {};
        }

        if (draft.products && draft.params.offset && draft.params.offset > 0) {
          const ref = draft.products.results;
          draft.products.results = ref?.concat(action.response.results);
          draft.products.last = action.response.last;
        } else {
          draft.products = { ...action.response };
        }
        break;

      default:
    }
  },
  initialState,
);

export default reducer;
