import { useRef } from 'react';

// components
import { SearchIndexOff } from 'components/SearchIndex/SearchIndexOff';
import NavigationRestriction from 'components/Navigation/NavigationRestriction';
import MainNavigation from 'components/Navigation/MainNavigation/MainNavigation';
import MetaNavigation from 'components/Navigation/MetaNavigation/MetaNavigation';
import { AnimatedLogo } from 'components/Logo/animated-logo';

// hooks
import useStickyHeader from '../../utils/use-sticky-header';
import useIsCampus from '../../utils/use-is-campus';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useClientSideRender } from 'utils/hooks/use-client-side-render';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

// styles
import styles from './header.module.scss';

function CountryHeader() {
  const headerRef = useRef<HTMLDivElement>(null);
  const isCampus = useIsCampus();
  const isDesktop = useIsDesktop();
  const isClient = useClientSideRender();

  const { isSticky, expandedClass, stickyClass } = useStickyHeader(headerRef);

  return (
    <SearchIndexOff>
      <div
        id="page-header"
        className={classNameBuilder(
          styles.pageHeader,
          stickyClass,
          expandedClass,
          'pageHeader--meta-nav',
        )}
        ref={headerRef}
        key={isDesktop ? 'nav-desktop' : 'nav-mobile'}
      >
        {isDesktop && <MetaNavigation isCampus={isCampus} />}
        <MainNavigation isSticky={isSticky} isCampus={isCampus} isDesktop={isDesktop} />
        {isClient && <AnimatedLogo />}
      </div>

      <NavigationRestriction />
    </SearchIndexOff>
  );
}

export default CountryHeader;
