import { TreePath, PathNode } from '../hooks/use-navigation-items';

export function shiftIndex(path: TreePath, lastItemSelectedIndex: number) {
  let index = path.length - 1;
  let node: PathNode | undefined;

  do {
    const clone = { ...path[index] };
    if (node) {
      path[index].selectedIndex = node.selectedIndex;
    } else {
      path[index].selectedIndex = lastItemSelectedIndex;
    }

    node = clone;
    index--;
  } while (index >= 0);
}
