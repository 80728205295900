import { useSelector } from 'react-redux';
import { Divider } from '@geberit/gdds';

// components
import { Translation } from 'components/Translation/Translation';
import { NavItem } from '../common/nav-item';
import { NavButton } from '../common/nav-button';

// styles
import { HorizontalMarginWrapper } from '../common/common.styles';
import { ProfileFlyoutWrapper } from './profile-flyout.styles';

// utils
import { useMetaNavigationInfo } from '../../../../utils/use-metanav';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';

type ProfileFlyoutProps = {
  onElementClick?: () => void;
  isOpen?: boolean;
};

export function ProfileFlyout({ isOpen, onElementClick }: Readonly<ProfileFlyoutProps>) {
  const { logout, orderHistoryUrl, webshopEnabled, ciamProfileUrl } = useMetaNavigationInfo();
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);

  return (
    <ProfileFlyoutWrapper isOpen={isOpen}>
      {webshopEnabled && !webshopDowntime && (
        <NavItem leftIconName="catalogue" href={orderHistoryUrl} onClick={onElementClick}>
          <Translation id="web20_ciam_order_history" />
        </NavItem>
      )}
      <NavItem leftIconName="profile" href={ciamProfileUrl} onClick={onElementClick}>
        <Translation id="web20_ciam_geberit_profile" />
      </NavItem>
      <HorizontalMarginWrapper>
        <Divider />
      </HorizontalMarginWrapper>
      <NavButton
        leftIconName="logout"
        onClick={() => {
          logout();
          onElementClick?.();
        }}
      >
        <Translation id="web20_ciam_logout" />
      </NavButton>
    </ProfileFlyoutWrapper>
  );
}
