import { combineReducers, Reducer } from 'redux';
import produce from 'immer';

import downloadCenterReducer from './features/download-center/reducer';
import globalsReducer from './features/browser-plugin/reducer';
import navigationReducer from './components/Navigation/reducer';
import pageReducer from './components/Page/reducer';
import productCatalogsReducer from './components/Product/reducer';
import translationReducer from './components/Translation/reducer';
import searchReducer from './components/Search/reducer';
import formReducer from './components/ContentElements/Form/reducer';
import basketReducer from './components/ShoppingBasket/reducer';
import webshopReducer from './components/Checkout/reducer';
import eventsReducer from './components/ContentElementsGdds/investors-calendar/reducer';
import { videocenterReducer } from './components/VideoGallery/reducer';
import { MaintenanceReducer } from './components/Maintenance/reducer';

type HomepageListAction = {
  type: 'homepageList/SET_CURRENT_LANGUAGE';
  language: string;
};

const initialState = {};
const homepageListReducer: Reducer<AppState['globals'], HomepageListAction> = produce(
  (draft: AppState['homepageList'], action: HomepageListAction) => {
    switch (action.type) { // NOSONAR
      case 'homepageList/SET_CURRENT_LANGUAGE':
        draft.currentLanguage = action.language;
        break;

      default:
    }
  },
  initialState,
);

const createReducers = () =>
  combineReducers({
    globals: globalsReducer,
    downloadCenter: downloadCenterReducer,
    homepageList: homepageListReducer,
    search: searchReducer,
    navigation: navigationReducer,
    page: pageReducer,
    productCatalogs: productCatalogsReducer,
    translations: translationReducer,
    form: formReducer,
    basket: basketReducer,
    webshop: webshopReducer,
    maintenance: MaintenanceReducer,
    events: eventsReducer,
    videoCenter: videocenterReducer,
  });

export default createReducers;
