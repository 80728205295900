import { useState, useEffect, useCallback } from 'react';
import { useDesktopFlyoutOpen } from '../mega-menu-provider';
import { useNavigation } from '../mainnav/navigation-provider';

export function useSelectedFirstLevelIndex() {
  const { isDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const { navigation } = useNavigation();
  const initialIndex = navigation?.selectedIndex === undefined ? -1 : navigation.selectedIndex;

  const [originalFirstLevelSelectedIndex, setOriginalFirstLevelSelectedIndex] =
    useState(initialIndex);
  const [selectedFirstLevelIndex, setSelectedFirstLevelIndex] = useState<number>(initialIndex);
  const [isSubLevelClicked, setIsSubLevelClicked] = useState(false);

  // Initially initialIndex changes multiple times
  useEffect(() => {
    setSelectedFirstLevelIndex(initialIndex);

    if (!isDesktopFlyoutOpen) {
      setOriginalFirstLevelSelectedIndex(initialIndex);
    }
    // Make sure to only run the effect if desktop flyout is closed (no other depedencies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIndex]);

  useEffect(() => {
    // Make sure to reset subLevelClicked when flyout is closed
    if (!isDesktopFlyoutOpen) {
      setIsSubLevelClicked(false);
    }
  }, [isDesktopFlyoutOpen]);

  useEffect(() => {
    // Once sub level item is clicked, move the original first level index
    if (isSubLevelClicked) {
      setOriginalFirstLevelSelectedIndex(initialIndex);
      setIsSubLevelClicked(false);
    }
  }, [initialIndex, isSubLevelClicked]);

  useEffect(() => {
    // When flyout is being closed
    if (!isDesktopFlyoutOpen && !isSubLevelClicked && navigation?.selectedIndex !== undefined) {
      setSelectedFirstLevelIndex(originalFirstLevelSelectedIndex);
    }
  }, [
    isDesktopFlyoutOpen,
    isSubLevelClicked,
    originalFirstLevelSelectedIndex,
    navigation?.selectedIndex,
  ]);

  const handleSubLevelClick = useCallback(() => {
    setIsSubLevelClicked(true);
  }, []);

  return {
    selectedFirstLevelIndex,
    handleSubLevelClick,
  };
}
