import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
  AppInsightsContext,
} from '@microsoft/applicationinsights-react-js';

import { bool } from 'config/env';
import { isServer } from 'isServer';
import { Log } from 'services/log';

// uc consent
let consentAI = false;
if (!isServer) {
  const storedConsent = window.localStorage.getItem('saved_uc_consent');
  if (storedConsent) {
    try {
      const parsedConsent = JSON.parse(storedConsent);
      consentAI = parsedConsent.Analysis;
    } catch {
      Log.error('Failed to parse stored consent', storedConsent);
    }
  }
}

function getBaseUrlHostname() {
  try {
    return new URL(process.env.BASE_URL ?? '').hostname;
  } catch {
    // this error should never happen or the website is down
    Log.error('Failed to parse url', process.env.BASE_URL);
    return '';
  }
}

let reactPlugin = new ReactPlugin();
if (consentAI && process.env.INSTRUMENTATION_KEY && !isServer && !bool(process.env.IS_PREVIEW)) {
  let appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.INSTRUMENTATION_KEY,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxPerfTracking: true,
      isBrowserLinkTrackingEnabled: true,
      disableFetchTracking: false,
      correlationHeaderDomains: [window.location.hostname, getBaseUrlHostname()],
      distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
      extensions: [reactPlugin],
    },
  });
  appInsights.loadAppInsights();
}

const AzureAppInsights = ({ children }) => {
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};

export const AppInsights = withAITracking(reactPlugin, AzureAppInsights);
