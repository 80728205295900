import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector, useStore } from 'react-redux';
import { debounce } from 'lodash';

// utils
import { globalsSearchSegmentSelector } from 'utils/selectors/globalsSelectors';
import getEndpoint from 'utils/endpoints';
import { Log } from 'services/log';

export function useSearchSuggestions(query: string) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const segment = useSelector(globalsSearchSegmentSelector);
  const state = useStore();
  const endpoint = `${getEndpoint('suggest', state)}`;

  const fetchSuggestions = useCallback(
    (query: string) => buildFetchSuggestions(segment, endpoint)(query),
    [segment, endpoint],
  );

  const debouncedFetchSuggestions = useRef(
    debounce(async (value: string) => {
      if (value.length >= 2) {
        const suggestions = await fetchSuggestions(value);
        setSuggestions(suggestions);
      } else {
        setSuggestions([]);
      }
    }, 200),
  );

  useEffect(() => {
    debouncedFetchSuggestions.current(query);
  }, [query, debouncedFetchSuggestions]);

  return suggestions;
}

function buildFetchSuggestions(segment: string | null, endpoint: string) {
  return async (query: string) => {
    try {
      const response = await axios.get(endpoint, {
        params: {
          segment,
          q: query,
        },
      });
      return response.data.suggestions;
    } catch (e) {
      Log.error(e, 'Could not fetch search suggestions');
    }
  };
}
