import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useNavOpen } from '../mega-menu-provider';

// constants
import { ANIMATION_DURATION } from '../mega-menu-header';

const AnimationContext = createContext({} as ReturnType<typeof useValue>);

function useValue() {
  const [animationType, setAnimationType] = useState<'deeper' | 'higher' | undefined>(undefined);
  const [animationPhase, setAnimationPhase] = useState<1 | 2>(1);

  const { isNavOpen } = useNavOpen();

  useEffect(() => {
    if (isNavOpen) {
      setTimeout(() => {
        if (isNavOpen) {
          setAnimationType('deeper');
          setAnimationPhase(2);
        }
      }, ANIMATION_DURATION);
    } else {
      setAnimationPhase(1);
      setAnimationType('higher');
    }
  }, [isNavOpen]);

  return {
    animationPhase,
    animationType,
    setAnimationPhase,
    setAnimationType,
  };
}

export function AnimationProvider({ children }: Readonly<PropsWithChildren>) {
  const value = useValue();

  return <AnimationContext.Provider value={value}>{children}</AnimationContext.Provider>;
}

export function useAnimation() {
  const { animationType, setAnimationType, animationPhase, setAnimationPhase } =
    useContext(AnimationContext);
  return { animationType, setAnimationType, animationPhase, setAnimationPhase };
}
