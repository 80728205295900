import { useContext } from 'react';

// components
import { SecondLevelNavItem } from './second-level-nav-item';

// styles
import { NavItemContainer } from 'mm/common/common.styles';

// utils
import { MegaMenuContext, useDesktopFlyoutOpen } from 'mm/mega-menu-provider';
import { PathNode } from 'mm/hooks/use-navigation-items';
import { navigationTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useNavigation, useNavigationIndex } from 'mm/mainnav/navigation-provider';
import { useReactKeys } from 'mm/language-switch/use-react-keys';
import { ThirdLevelNavigation } from '../third-level/third-level-navigation';
import { decodingContent } from 'utils/decodingContent';
import { useTracking as useTracking2 } from 'utils/tracking/track';

type SecondLevelNavigationItemsProps = {
  pathNode?: PathNode;
  navItemRefs?: React.MutableRefObject<any[]>;
  withPictogram?: boolean;
};

export function SecondLevelNavigationItems({
  pathNode,
  withPictogram,
}: Readonly<SecondLevelNavigationItemsProps>) {
  const navLevel = pathNode!.depth + 1;

  const { pushPathNode, findPath, thirdLevelNavigation } = useNavigation();
  const { handleSubLevelClick } = useNavigationIndex();
  useContext(MegaMenuContext);
  const { setIsDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const track = useTracking();
  const { trackClick } = useTracking2();
  const { isDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const processedChildren = pathNode?.children;
  const childrenWithKeys = useReactKeys(processedChildren ?? [], ['ariaLabel', 'label', 'url']);

  return (
    <>
      {childrenWithKeys.map((navItem) => {
        const isSelected = isNavItemSelected(pathNode!, navItem.originalIndex);
        const hasNoChildren = !navItem.children?.length;
        const notClickable =
          isSelected &&
          (hasNoChildren ||
            pathNode?.depth === 2 ||
            (pathNode?.depth === 1 && thirdLevelNavigation?.selectedIndex === undefined));

        return (
          <NavItemContainer
            className={navItem.isLast ? 'spacer' : ''}
            level={navLevel}
            key={navItem.key}
          >
            <SecondLevelNavItem
              shallow={!navItem.url}
              href={navItem?.url || '/'}
              aria-label={navItem?.ariaLabel}
              selected={isSelected}
              onClick={getNavItemClickHandler(
                { ...navItem, depth: pathNode!.depth + 1 },
                navItem.originalIndex,
                navItem.url,
              )}
              tabIndex={isDesktopFlyoutOpen ? 0 : -1}
              notClickable={notClickable}
              leftIconUrl={navItem.image}
              rightIconName={navItem?.children && navItem?.children?.length > 0 && 'chevron_right'}
            >
              {decodingContent(navItem.label ?? '')}
            </SecondLevelNavItem>
            {isSelected && <ThirdLevelNavigation />}
          </NavItemContainer>
        );
      })}
    </>
  );

  function isNavItemSelected(pathNode: PathNode, index: number): boolean | undefined {
    // in desktop, navigation stands only for the first level,
    // so index === navigation?.selectedIndex means that the element is selected
    // secondLevelNavigation & thirdLevelNavigation is for second/third level
    return index === pathNode?.selectedIndex;
  }

  function handlePathNodeChildren(pathNode: PathNode, index: number) {
    // A click on navigation level 1 does not cause a url change
    // and our hook to get the treePath is not triggered automatically
    // so we have to trigger the function to get the treePath manually
    if (pathNode.depth === 1) {
      findPath();
    }
    pushPathNode({ ...pathNode }, index);
  }

  function getNavItemClickHandler(pathNode: PathNode, index: number, url: string) {
    return () => {
      handleSubLevelClick();

      if (pathNode.children) {
        handlePathNodeChildren(pathNode, index);
      } else {
        setIsDesktopFlyoutOpen(false);
      }

      if (url) {
        track.trackEvent(navigationTrackingAction(pathNode.label));
        trackClick({
          click_intent: 'navigation',
          click_text: pathNode.label,
          click_element: `navigation level ${pathNode.depth}`,
          click_url: url,
        });
      }
    };
  }
}
