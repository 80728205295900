import { css } from 'styled-components';

export type AnimationProps = {
  isOpen: boolean;
};

export function getFlyoutAnimationStyle({ isOpen }: AnimationProps) {
  return css`
    pointer-events: ${isOpen ? 'all' : 'none'};
    opacity: ${isOpen ? 1 : 0};
    padding: ${isOpen ? '0.5rem 0' : '0 0'};
    transition:
      opacity 0.2s ease,
      padding 0.2s ease;
    a {
      pointer-events: ${isOpen ? 'all' : 'none'};
    }
  `;
}
