import { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useNavigationIndex } from '../navigation-provider';
import { useDesktopFlyoutOpen } from '../../mega-menu-provider';

export default function FirstLevelIndicator() {
  const { selectedFirstLevelIndex } = useNavigationIndex();
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const { isDesktopFlyoutOpen } = useDesktopFlyoutOpen();

  const updateIndicator = useCallback(() => {
    const lis = document.querySelectorAll('.first-level > li');
    if (!lis) return;

    const activeItemRef = lis[selectedFirstLevelIndex];
    if (activeItemRef) {
      const rect = activeItemRef.getBoundingClientRect();

      setIndicatorStyle({
        left: `${rect.left}px`,
        width: `${rect.width}px`,
      });
    } else {
      setIndicatorStyle({
        left: 0,
        width: 0,
      });
    }
  }, [selectedFirstLevelIndex]);

  // useEffect for changes in navItemRefs
  useEffect(() => {
    const timeoutId = setTimeout(updateIndicator, 300); // delay for language switch
    return () => clearTimeout(timeoutId); // cleanup in case the component is unmounted
  }, [updateIndicator]);

  // useEffect for changes in selectedFirstLevelIndex, isDesktopFlyoutOpen
  useEffect(() => {
    setTimeout(() => {
      updateIndicator();
    }, 500);
  }, [selectedFirstLevelIndex, isDesktopFlyoutOpen, updateIndicator]);

  useEffect(() => {
    // Attach resize event listener
    window.addEventListener('resize', updateIndicator);
    // Cleanup on component unmount
    return () => window.removeEventListener('resize', updateIndicator);
  }, [updateIndicator]);

  return <Container style={indicatorStyle} />;
}

const Container = styled.div(({ theme }) => {
  return css`
    --height: 0.25rem;
    height: var(--height);
    background-color: ${theme.colors.primaryLight};
    width: 0px;
    position: absolute;
    top: calc(var(--first-level-nav-height, 56px) - var(--height));
    transition:
      left 0.3s,
      width 0.3s;
  `;
});
