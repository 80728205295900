// components
import { Translation } from 'components/Translation/Translation';

// styles
import { BackButton } from 'mm/common/common.styles';
import { NavControls } from './navigation-controls.styles';

// constants
import { ANIMATION_DURATION } from 'mm/mega-menu-header';

// utils
import { useNavigation } from '../navigation-provider';
import { useAnimation } from '../animation-provider';
import { decodingContent } from 'utils/decodingContent';

export function NavigationControls() {
  const { handleOnBack, navigation } = useNavigation();
  const { setAnimationPhase, setAnimationType } = useAnimation();

  if (!navigation?.label) {
    return null;
  }
  const handleBackWithAnimation = () => {
    setAnimationPhase(1);
    setAnimationType('higher');
    setTimeout(() => {
      handleOnBack();
      setAnimationPhase(2);
    }, ANIMATION_DURATION);
  };

  return (
    <NavControls thirdLevel={navigation.depth === 2}>
      <BackButton leftIconName="chevronLeft" onClick={handleBackWithAnimation}>
        <BackButtonLabel parentLabel={decodingContent(navigation.label)} />
      </BackButton>
    </NavControls>
  );
}

const BackButtonLabel = ({ parentLabel }) => {
  return parentLabel ? <span>{parentLabel}</span> : <Translation id="web20_menu_back" />;
};
