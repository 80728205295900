import { useContext } from 'react';
import key from 'weak-key';

// components
import { CmsLink } from '../Link/CmsLink';
import { Message } from '../Message/Message';
import { NAVIGATION_RESTRICTIONS } from './constants';

// styles
import styles from '../../features/header/components/header/header.module.scss';
import { PreviewContext } from '../ContentCreator/PreviewContext';
import { useIsPreview } from '../../utils/hooks/useIsPreview';

function NavigationRestriction() {
  const isPreview = useIsPreview();
  const { state: { navigationRestrictions } = {} } = useContext(PreviewContext) || {};

  if (!isPreview || !navigationRestrictions) return null;

  return (
    <Message
      type="alert"
      content={
        <div className="grid-container">
          Navigation menu too long. More than 60 characters.
          <ul>
            {navigationRestrictions.map((el) => (
              <li key={key(el)} className={styles.toolong}>
                <CmsLink link={{ target: el.url, type: 'internal_link' }}>
                  {el.label}
                  <strong>{el.toolong}</strong>
                </CmsLink>
                <div>
                  <strong>
                    {`${(el.label + el.toolong).length}
                           / ${NAVIGATION_RESTRICTIONS[el.level]} `}
                  </strong>{' '}
                  characters used | <strong>Path: </strong>
                  {el.path.length === 0 && <>Root level</>}
                  {el.path.map((pathParent, i) => (
                    <>
                      <CmsLink link={{ target: pathParent.url, type: 'internal_link' }}>
                        {pathParent.label}
                      </CmsLink>
                      {i !== el.path.length - 1 && <span> / </span>}
                    </>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      }
      closable={false}
    />
  );
}

export default NavigationRestriction;
