import { styleConfig } from '../../config';

type Sizes = { width: number };
export type ViewportName = 'xl' | 'l' | 'm' | 's' | 'xs';

export const isMobileSelector = (sizes: Sizes) => sizes.width <= styleConfig.responsive.mobileMax;

export const isTabletSelector = (sizes: Sizes) =>
  sizes.width >= styleConfig.responsive.tabletMin &&
  sizes.width <= styleConfig.responsive.tabletMax;

export const isGddsTabletSelector = (sizes: Sizes) =>
  sizes.width >= styleConfig.responsive.tabletMin &&
  sizes.width <= styleConfig.responsive.tabletGddsMax;

export const isDesktopSelector = (sizes: Sizes) =>
  sizes.width >= styleConfig.responsive.desktopMin &&
  sizes.width < styleConfig.responsive.desktopMax;

export const isGddsLargeDesktopSelector = (sizes: Sizes) =>
  sizes.width >= styleConfig.responsive.largeDesktopGddsMin &&
  sizes.width < styleConfig.responsive.desktopMax;

export const isGddsDesktopSelector = (sizes: Sizes) =>
  sizes.width >= styleConfig.responsive.desktopGddsMin &&
  sizes.width < styleConfig.responsive.desktopMax;

export const currentViewportSelector = (sizes: Sizes): ViewportName => {
  if (sizes.width < styleConfig.responsive.mobileMin) return 'xs';
  if (isMobileSelector(sizes)) return 's';
  if (isTabletSelector(sizes)) return 'm';
  if (
    sizes.width >= styleConfig.responsive.desktopMin &&
    sizes.width < styleConfig.responsive.largeDesktopMin
  )
    return 'l';
  return 'xl';
};
export const currentGddsViewportSelector = (sizes: Sizes): ViewportName => {
  if (sizes.width < styleConfig.responsive.mobileMin) return 'xs';
  if (isMobileSelector(sizes)) return 's';
  if (isGddsTabletSelector(sizes)) return 'm';
  if (
    sizes.width >= styleConfig.responsive.desktopMin &&
    sizes.width < styleConfig.responsive.largeDesktopGddsMin
  )
    return 'l';
  if (isGddsLargeDesktopSelector(sizes)) return 'xl';
  return 'xl';
};
