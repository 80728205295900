import styled, { css } from 'styled-components';
import { useMainNavShown } from '../mega-menu-provider';

export const Container = styled.div<{ expand: boolean }>(({ theme, expand }) => {
  const { isMainNavShown } = useMainNavShown();

  return css`
    padding: 1.5rem 0;
    transition:
      height,
      min-height 0.2s ease-in-out;
    box-sizing: content-box;
    @media ${theme.device.large} {
      display: grid;
      grid-template-rows: ${expand ? '465px' : 'var(--first-level-nav-height, 56px)'};
      transition-delay: ${expand ? '0s' : 'var(--animation-duration)'};
      transition-property: grid-template-rows;
      transition-duration: var(--animation-duration);
      padding: 0;
      > div {
        width: 100%;
        ${isMainNavShown && 'position: unset;'}
      }
    }
  `;
});

export const SearchWrapper = styled.div`
  width: 37.5rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 12.5rem;

  & .search-animated-logo {
    position: absolute;
    bottom: -91px;
    margin-top: unset;
    right: 1.5rem;
  }
`;
