// components
import { FirstLevelNavigationItems } from './first-level/first-level-navigation-items';
import FirstLevelIndicator from './first-level-indicator';

// utils
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useShowFirstLevelIndicator } from 'mm/hooks/use-show-first-level-indicator';
import { useNavigation } from '../navigation-provider';

export function DesktopNavigation() {
  const { navigation } = useNavigation();
  const language = useCurrentLanguage();
  const showFirstLevelIndicator = useShowFirstLevelIndicator();

  if (!navigation) {
    return null;
  }

  return (
    <>
      <FirstLevelNavigationItems pathNode={navigation} />
      {showFirstLevelIndicator && <FirstLevelIndicator key={language} />}
    </>
  );
}
