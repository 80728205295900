// styles
import { DesktopMainNavContainer } from '../common.styles';

// utils
import { SecondLevelNavigationItems } from './second-level-navigation-items';
import { useNavigation } from 'mm/mainnav/navigation-provider';
import { useDesktopFlyoutOpen } from 'mm/mega-menu-provider';
import { AnimationLayerContainer } from 'mm/common/common.styles';
import { SecondLevelNavDesktop } from './second-level-navigation.styles';

export function SecondLevelNavigation() {
  const { secondLevelNavigation } = useNavigation();

  const { isDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const desktopAnimationPhase = isDesktopFlyoutOpen ? 2 : 1;
  const desktopAnimationType = isDesktopFlyoutOpen ? 'higher' : 'deeper';

  if (!secondLevelNavigation) {
    return null;
  }

  return (
    <SecondLevelNavDesktop >
      <AnimationLayerContainer
        className="withDesktopAnimation"
        animationPhase={desktopAnimationPhase}
        animationType={desktopAnimationType}
        withDelay={isDesktopFlyoutOpen}
      >
        <DesktopMainNavContainer>
          <SecondLevelNavigationItems pathNode={secondLevelNavigation} />
        </DesktopMainNavContainer>
      </AnimationLayerContainer>
    </SecondLevelNavDesktop>
  );
}
