import produce from 'immer';
import { ProductAction } from './actions';
import { ProductState } from './reducer.types';
import { Reducer } from 'redux';

const initialState = {};

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<ProductState, ProductAction> = produce(
  (draft: ProductState, action: ProductAction) => {
    if ('catalog' in action) {
      draft[action.catalog] = draft[action.catalog] || {
        products: {},
        sections: {},
        categories: {},
      };

      if (action.sectionId && draft[action.catalog].sections[action.sectionId] === undefined) {
        draft[action.catalog].sections[action.sectionId] = {};
      }

      if (action.categoryId && draft[action.catalog].categories[action.categoryId] === undefined) {
        draft[action.catalog].categories[action.categoryId] = {};
      }

      if (action.product?.key && draft[action.catalog].products[action.product.key] === undefined) {
        draft[action.catalog].products[action.product.key] = {};
      }

      if (action.productKey && draft[action.catalog].products[action.productKey] === undefined) {
        draft[action.catalog].products[action.productKey] = {};
      }
    }

    switch (action.type) {
      case 'ADD_PRODUCT':
        Object.keys(action.product).forEach((key) => {
          draft[action.catalog].products[action.product.key][key] = action.product[key];
        });
        draft[action.catalog].products[action.product.key].lastFetched = action.lastFetched;
        break;

      case 'PRODUCTS_REQUEST_PENDING':
        draft[action.catalog].sections[action.sectionId].isFetching = true;
        break;

      case 'PRODUCTS_REQUEST_FULFILLED':
        draft[action.catalog].sections[action.sectionId].isFetching = false;
        draft[action.catalog].sections[action.sectionId].error = false;
        draft[action.catalog].sections[action.sectionId].productKeys = action.productKeys;
        break;

      case 'PRODUCTS_REQUEST_REJECTED':
        draft[action.catalog].sections[action.sectionId].isFetching = false;
        draft[action.catalog].sections[action.sectionId].error = {
          status: action.error.status,
          statusText: action.error.statusText,
        };
        break;

      case 'PRODUCTS_BY_CATEGORY_REQUEST_PENDING':
        draft[action.catalog].categories[action.categoryId].isFetching = true;
        break;

      case 'PRODUCTS_BY_CATEGORY_REQUEST_FULFILLED':
        draft[action.catalog].categories[action.categoryId].isFetching = false;
        draft[action.catalog].categories[action.categoryId].error = false;
        draft[action.catalog].categories[action.categoryId].lastFetched = action.lastFetched;

        // for PLP
        if (typeof action.categoryResult === 'string') {
          draft[action.catalog].categories[action.categoryId].categoryId = action.categoryResult;
          draft[action.catalog].categories[action.categoryId].total = action.total;
        } else {
          // tiles 2 categories
          draft[action.catalog].categories[action.categoryId].pictureObject =
            action.categoryResult.pictureObject;
          draft[action.catalog].categories[action.categoryId].pictureObjectMobile =
            action.categoryResult.pictureObjectMobile;
          draft[action.catalog].categories[action.categoryId].title = action.categoryResult.title;
          draft[action.catalog].categories[action.categoryId].subtitle =
            action.categoryResult.subtitle;
          draft[action.catalog].categories[action.categoryId].categoryId =
            action.categoryResult.categoryCatalogId;
          draft[action.catalog].categories[action.categoryId].previewId =
            action.categoryResult.previewId;
          draft[action.catalog].categories[action.categoryId].total = action.total;
          draft[action.catalog].categories[action.categoryId].name = action.catName;
        }

        if (draft[action.catalog].categories[action.categoryId].productKeys) {
          // no duplicates
          draft[action.catalog].categories[action.categoryId].productKeys = [
            ...new Set(
              draft[action.catalog].categories[action.categoryId].productKeys.concat(
                action.productKeys,
              ),
            ),
          ];
        } else {
          draft[action.catalog].categories[action.categoryId].productKeys = action.productKeys;
        }
        break;

      case 'PRODUCTS_BY_CATEGORY_REQUEST_REJECTED':
        draft[action.catalog].categories[action.categoryId].isFetching = false;
        draft[action.catalog].categories[action.categoryId].error = {
          status: action.error.status,
          statusText: action.error.statusText,
        };
        break;

      case 'ALTERNATIVE_PRODUCTS_BY_CATEGORY_REQUEST_PENDING':
        draft[action.catalog].products[action.productKey].isFetching = true;
        break;

      case 'ALTERNATIVE_PRODUCTS_BY_CATEGORY_REQUEST_FULFILLED':
        draft[action.catalog].products[action.productKey].isFetching = false;
        draft[action.catalog].products[action.productKey].error = false;
        draft[action.catalog].products[action.productKey].alternativeProducts =
          action.alternativeProducts;
        break;

      case 'ALTERNATIVE_PRODUCTS_BY_CATEGORY_REQUEST_REJECTED':
        draft[action.catalog].products[action.productKey].isFetching = false;
        draft[action.catalog].products[action.productKey].error = {
          status: action.error.status,
          statusText: action.error.statusText,
        };
        break;
      default:
    }
  },
  initialState,
);

export default reducer;
