import styled, { css } from 'styled-components';

// types
import type { AnimationProps } from '../utils/get-flyout-animation-styles';

// utils
import { getFlyoutAnimationStyle } from '../utils/get-flyout-animation-styles';

export const LocatorFlyoutContainer = styled.div<AnimationProps>`
  ${({ theme }) => {
    return css`
      flex-direction: column;
      white-space: nowrap;
      position: absolute;
      top: 2.5rem;
      z-index: 100;
      right: 0;
      background-color: ${theme.colors.cl01};
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.26);
      ${getFlyoutAnimationStyle}

      @media ${theme.device.medium} {
        left: 0;
        right: auto;
      }
    `;
  }}
`;
