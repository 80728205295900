import styled, { css } from 'styled-components';

// types
import type { AnimationProps } from '../utils/get-flyout-animation-styles';

// utils
import { getFlyoutAnimationStyle } from '../utils/get-flyout-animation-styles';

export const LanguageSwitchWrapper = styled.div<{ isOpen: boolean }>(({ theme, isOpen }) => {
  return css`
    display: none;

    @media ${theme.device.large} {
      display: initial;
    }

    position: relative;
    border: 1px solid transparent;

    &:focus,
    &:focus-visible {
      border: 1px solid ${theme.colors.primaryLight};
      outline: 1px solid ${theme.colors.primaryLight};
    }

    button {
      color: ${theme.colors.black};
      padding-left: 0.5rem;

      span {
        ${isOpen ? 'transform: rotate(-180deg);' : '  transform: rotate(0deg);'}
      }

      svg {
        width: 1.25rem;
        fill: ${theme.colors.black};
      }

      &.selected {
        background-color: rgba(${theme.colors.transparentBlack}, 0.14);
      }
    }
  `;
});

export const DropdownFlyout = styled.div<AnimationProps>`
  ${({ theme }) => {
    return css`
      position: absolute;
      top: 2.5rem;
      left: 0rem;
      width: max-content;
      background-color: ${theme.colors.cl01};
      z-index: 100;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.26);
      ${getFlyoutAnimationStyle}
    `;
  }}
`;
