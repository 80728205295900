import { useEffect } from 'react';
import { Container } from '@geberit/gdds';

// components
import { LocatorSwitch } from './locator-switch';

// styles
import { AnimationLayer, AnimationWrapper } from '../common/common.styles';

// utils
import { useHeaderPin } from 'utils/hooks/use-header-pin';
import { useLocatorPageOpen } from '../mega-menu-provider';
import { useAnimate } from '../hooks/use-animate';

export function NavBarLocatorSelector() {
  const { isLocatorPageOpen } = useLocatorPageOpen();
  const { entries = [] } = useHeaderPin();
  const { closeFlyout, openFlyout, animationType, onAnimationEnd } = useAnimate();

  useEffect(() => {
    if (!isLocatorPageOpen) closeFlyout();
  }, [isLocatorPageOpen]);

  return (
    <AnimationWrapper
      onTransitionEnd={() => {
        if (isLocatorPageOpen) {
          openFlyout();
        }
      }}
      animationType={isLocatorPageOpen ? 'fadeIn' : 'fadeOut'}
    >
      {entries.length > 0 && (
        <Container maxContentWidth="80rem">
          <AnimationLayer animationType={animationType} onAnimationEnd={onAnimationEnd}>
            <LocatorSwitch items={entries} />
          </AnimationLayer>
        </Container>
      )}
    </AnimationWrapper>
  );
}
