import styled, { css } from "styled-components";

export const MobileMainNavContainer = styled.ul(({ theme }) => {
  return css`
    padding: 0 0 1.5rem 0;
    margin: 0;

    @media ${theme.device.large} {
      display: none;
    }
  `;
});