import styled, { createGlobalStyle, css } from 'styled-components';
import { useNord } from 'utils/hooks/use-nord';

export const GlobalMainNavigationStyles = createGlobalStyle`
  .c-search-bar-form {
    border-top: none;
  }

  .c-search-bar-form--white-bg-active {
    border-top: ${({ theme }) => `1px solid ${theme.colors.cl08}`}
  }
`;

export const MainNavigationWrapper = styled.div(() => {
  const isNord = useNord();

  return css`
    a {
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      &::before {
        content: none;
      }
    }

    ul li {
      padding-bottom: 0;
    }
    nav ul[role='menubar'] li ul[role='menu'] {
      z-index: 1;
    }

    ${isNord &&
    css`
      [data-logo] {
        width: auto;
        height: 2.25rem;
      }
    `}
  `;
});
