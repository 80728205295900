import * as React from 'react';
import _throttle from 'lodash.throttle';
import {
  NavigationContext,
  NAVIGATION_OPEN,
  UPDATE_HEIDER_HEIGHT,
} from '../../../components/Navigation/NavigationContext';
import useIsCampus from './use-is-campus';
import useTouchDetector from '../../../utils/hooks/use-touch-detector';
import { useGdds } from '../../../utils/hooks/use-gdds';
import { isDesktopSelector } from '../../../utils/selectors/sizeSelectors';

const FIXED_HEADER_SCROLL_POS = 30;

export default function useStickyHeader(headerRef: React.MutableRefObject<HTMLDivElement | null>) {
  const { state, dispatch } = React.useContext(NavigationContext) || {};
  const { flyoutState } = state || {};
  const lastScroll = React.useRef(0);
  const scrollPosition = React.useRef(0);
  const [isSticky, setIsSticky] = React.useState(false);
  const [isExpanded, setExpanded] = React.useState(true);
  const [isDesktopRender, setDesktopRender] = React.useState(false);
  const isCampus = useIsCampus();
  const isGdds = useGdds();
  const isTouch = useTouchDetector() && isGdds;

  React.useEffect(() => {
    const desktopValue = isDesktopSelector({ width: window.innerWidth });
    setDesktopRender(desktopValue);
  }, []);

  const updateHeaderHeight = React.useCallback(() => {
    if (headerRef.current && state && state.headerHeight !== headerRef.current.scrollHeight) {
      const height = headerRef.current.scrollHeight;
      dispatch({ type: UPDATE_HEIDER_HEIGHT, height });
    }
  }, []);

  const onScroll = React.useCallback(
    _throttle(() => {
      if (flyoutState !== NAVIGATION_OPEN && typeof window !== 'undefined') {
        const windowScroll = window.scrollY;
        const direction = windowScroll <= scrollPosition.current ? 'UP' : 'DOWN';

        const setSticky = windowScroll > FIXED_HEADER_SCROLL_POS && windowScroll > 100;
        setIsSticky(setSticky);

        if (setSticky) {
          if (direction === 'UP' && lastScroll.current - windowScroll >= FIXED_HEADER_SCROLL_POS) {
            setExpanded(true);
          } else {
            setExpanded(false);
            lastScroll.current = windowScroll;
          }
          scrollPosition.current = windowScroll;
        } else {
          setExpanded(true);
        }
      }
    }, 20),
    [],
  );

  const onAnimationEnd = () => {
    updateHeaderHeight();
  };

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll);
    document.addEventListener('resize', onAnimationEnd);

    return () => {
      document.removeEventListener('scroll', onScroll);
      document.removeEventListener('resize', onAnimationEnd);
    };
  }, [flyoutState]);

  React.useEffect(() => {
    if (headerRef.current) {
      headerRef.current.addEventListener('transitionend', onAnimationEnd);
    }

    return () => {
      if (headerRef.current) {
        headerRef.current.removeEventListener('transitionend', onAnimationEnd);
      }
    };
  }, [headerRef.current]);

  const stickyClass =
    isTouch || !isDesktopRender || (isSticky && !isCampus) ? 'pageHeader--sticky' : '';
  const expandedClass =
    !isCampus && isExpanded && isDesktopRender && !isTouch
      ? 'pageHeader--full'
      : 'pageHeader--reduced';

  return {
    isSticky,
    stickyClass,
    expandedClass,
  };
}
