import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// utils
import {
  navigationMainCampusItemsSelector,
  navigationMainItemsSelector,
} from 'utils/selectors/navigationSelectors';
import useIsCampus from '../../features/header/utils/use-is-campus';

export function useNavigation() {
  const isCampus = useIsCampus();
  const navigation = useSelector(navigationMainItemsSelector);
  const campusNavigation = useSelector(navigationMainCampusItemsSelector);

  return useMemo(() => {
    let counter = 0;
    let items = navigation ?? [];
    while (counter < 2) {
      items = items.flatMap((e) => e.children ?? []) ?? [];
      counter++;
    }
    items.forEach((e) => {
      e.children = undefined;
    });

    return isCampus ? campusNavigation ?? [] : navigation ?? [];
  }, [campusNavigation, isCampus, navigation]);
}
