import { useEffect } from 'react';

// utils
import { useNavOpen, useSearchOpen } from '../mega-menu-provider';
import { useIsDesktop } from 'components/App/SizeProvider';

export function useToggleChatWidgetButtons() {
  const { isNavOpen } = useNavOpen();
  const { isSearchOpen } = useSearchOpen();
  const isDesktop = useIsDesktop();

  const hide = (element: HTMLElement | null) => {
    if (element) {
      element.style.display = 'none';
    }
  };

  const show = (element: HTMLElement | null) => {
    if (element) {
      element.style.display = 'block';
    }
  };

  useEffect(() => {
    const iframe = document.querySelector<HTMLElement>(
      '#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window',
    );
    const container = document.querySelector<HTMLElement>('#livechat-basetag');

    if (isNavOpen || (!isDesktop && isSearchOpen)) {
      hide(iframe);
      hide(container);
    } else {
      show(iframe);
      show(container);
    }
  }, [isNavOpen, isDesktop, isSearchOpen]);
}
