import styled, { css } from 'styled-components';

export const StyledSpan = styled.span(() => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;

    label {
      color: currentColor;
      cursor: pointer;
    }
  `;
});
