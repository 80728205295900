import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const createReduxStore = (url = '/', initialStore = {}) => {
  const preloadedState = initialStore;

  type Middleware = () => (next) => (action) => any;

  /**
   * This array holds all middlewares that should be used by the
   * React environment.
   *
   * @type {Array.<Object>}
   */
  const middlewares: Middleware[] = [thunk as unknown as Middleware];
  // On a development system: use Redux devtools, if available
  let composeEnhancers = compose;
  if (
    typeof window !== 'undefined' &&
    (window.location.hostname === 'localhost' ||
      window.location.hostname === 'local.web.geberit.com' ||
      window.location.hostname.endsWith('.dev.platform.web.geberit.com')) &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  /**
   * This constant is a composition of all middlewares.
   *
   * @type {Object}
   */
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  /**
   * This function creates the whole applications store based on the
   * Redux structure.
   *
   * @type {ReduxStore}
   */
  const store = createStore(reducer(), preloadedState, enhancer);

  return {
    store,
  };
};

export default createReduxStore;
