import produce from 'immer';
import { Reducer } from 'redux';
import { CheckoutAction } from './actions';
import { CheckoutState } from './reducer.types';

const initialState = {
  activeStep: 0,
  isFetchingCart: false,
  cart: {},
  errorCode: null,
  cartError: false,
  invoiceAddress: {},
  deliveryAddress: {},
  isFetchingUserData: false,
  userData: {},
  userDataError: false,
  isFetchingOrderData: false,
  orderData: {},
  orderError: false,
  isFetchingSimulatedOrderData: false,
  simulatedOrderData: {},
  simulatedOrderError: false,
  isFetchingPaymentData: false,
  paymentData: {},
  paymentDataError: false,
  isFetchingZipCodeValidation: false,
  zipCodeValidationData: {},
  deliveryZipCodeValidationData: {},
  zipCodeValidationError: false,
  zipCodeValidationField: '',
};

/**
 * @param {Object} draft – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<CheckoutState, CheckoutAction> = produce(
  (draft: CheckoutState, action: CheckoutAction) => {
    switch (action.type) {
      case 'webshop/checkout/IS_FETCHING_CART':
        draft.isFetchingCart = action.isFetching;
        break;
      case 'webshop/checkout/SET_CART_DATA':
        draft.cart = action.data;
        break;
      case 'webshop/checkout/SET_CART_ERROR':
        draft.cartError = action.error;
        draft.errorCode = 700;
        break;
      case 'webshop/checkout/IS_FETCHING_USER_DATA':
        draft.isFetchingUserData = action.isFetching;
        break;
      case 'webshop/checkout/SET_USER_DATA':
        draft.userData = action.data;
        break;
      case 'webshop/checkout/SET_USER_DATA_ERROR':
        draft.userDataError = action.error;
        draft.errorCode = 720;
        break;
      case 'webshop/checkout/CHANGE_ACTIVE_STEP':
        if (typeof window !== 'undefined') window.scrollTo({ top: 0, behavior: 'smooth' });
        draft.activeStep = action.step;
        break;
      case 'webshop/checkout/SET_INVOICE_ADDRESS':
        draft.invoiceAddress = action.address;
        break;
      case 'webshop/checkout/SET_DELIVERY_ADDRESS':
        draft.deliveryAddress = action.address;
        break;
      case 'webshop/checkout/IS_FETCHING_ORDER_DATA':
        draft.isFetchingOrderData = action.isFetching;
        break;
      case 'webshop/checkout/SET_ORDER_DATA':
        draft.orderData = action.data;
        break;
      case 'webshop/checkout/SET_ORDER_ERROR':
        draft.orderError = action.error;
        draft.errorCode = 740;
        break;
      case 'webshop/checkout/IS_FETCHING_SIMULATED_ORDER_DATA':
        draft.isFetchingSimulatedOrderData = action.isFetching;
        break;
      case 'webshop/checkout/SET_SIMULATED_ORDER_DATA':
        draft.deliveryAddress = {
          ...draft.deliveryAddress,
          ...action.data.deliveryAddress,
        };
        draft.invoiceAddress = {
          ...draft.invoiceAddress,
          ...action.data.invoiceAddress,
        };
        draft.simulatedOrderData = action.data;
        break;
      case 'webshop/checkout/SET_SIMULATED_ORDER_ERROR':
        draft.simulatedOrderError = action.error;
        draft.errorCode = 740;
        break;
      case 'webshop/checkout/IS_FETCHING_PAYMENT_DATA':
        draft.isFetchingPaymentData = action.isFetching;
        break;
      case 'webshop/checkout/SET_PAYMENT_DATA':
        draft.paymentData = action.data;
        break;
      case 'webshop/checkout/SET_PAYMENT_ERROR':
        draft.paymentDataError = action.error;
        draft.errorCode = 760;
        break;
      case 'webshop/checkout/IS_FETCHING_ZIP_CODE_VALIDATION':
        draft.isFetchingZipCodeValidation = action.isFetching;
        break;
      case 'webshop/checkout/SET_ZIP_CODE_VALIDATION_DATA':
        draft.zipCodeValidationData = action.data;
        break;
      case 'webshop/checkout/SET_DELIVERY_ZIP_CODE_VALIDATION_DATA':
        draft.deliveryZipCodeValidationData = action.data;
        break;
      case 'webshop/checkout/SET_ZIP_CODE_VALIDATION_FIELD_NAME':
        draft.zipCodeValidationField = action.data;
        break;
      case 'webshop/checkout/SET_ZIP_CODE_VALIDATION_ERROR':
        draft.zipCodeValidationError = action.error;
        break;
      case 'webshop/checkout/RESET_CHECKOUT_STATE':
        draft.activeStep = 0;
        draft.isFetchingCart = false;
        draft.cart = {};
        draft.cartError = false;
        draft.errorCode = null;
        draft.invoiceAddress = {};
        draft.deliveryAddress = {};
        draft.isFetchingUserData = false;
        draft.userData = {};
        draft.userDataError = false;
        draft.isFetchingOrderData = false;
        draft.orderData = {};
        draft.orderError = false;
        draft.isFetchingSimulatedOrderData = false;
        draft.simulatedOrderData = {};
        draft.simulatedOrderError = false;
        draft.isFetchingPaymentData = false;
        draft.paymentData = {};
        draft.paymentDataError = false;
        draft.isFetchingZipCodeValidation = false;
        draft.zipCodeValidationData = {};
        draft.deliveryZipCodeValidationData = {};
        draft.zipCodeValidationError = false;
        draft.zipCodeValidationField = '';
        break;
      default:
    }
  },
  initialState,
);

export default reducer;
