// components
import { MobileNavigationItems } from './mobile-navigation-items';
import { AnimationLayerContainer } from 'mm/common/common.styles';
import { MobileMainNavContainer } from './mobile-navigation.styles';
import { NavigationControls } from './navigation-controls';

// utils
import { useNavigation } from '../navigation-provider';
import { useAnimation } from '../animation-provider';

export function MobileNavigation() {
  const { navigation } = useNavigation();

  const { animationType, animationPhase } = useAnimation();

  if (!navigation) {
    return null;
  }

  const pathNodeClone = { ...navigation };

  return (
    <MobileMainNavContainer className={navigation.withPictogram ? 'pictogram' : undefined}>
      <AnimationLayerContainer
        animationType={animationType}
        animationPhase={animationPhase}
        withDelay
      >
        <NavigationControls />
        <MobileNavigationItems pathNode={pathNodeClone} />
      </AnimationLayerContainer>
    </MobileMainNavContainer>
  );
}
