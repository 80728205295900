import produce from 'immer';
import { Reducer } from 'redux';
import { DownloadCenterAction } from './actions';
import { DownloadCenterState } from './reducer.types';

const initialState = {};

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<DownloadCenterState, DownloadCenterAction> = produce(
  (draft: DownloadCenterState, action: DownloadCenterAction) => {
    if ('language' in action && draft[action.language] === undefined) {
      draft[action.language] = {};
    }

    switch (action.type) {
      case 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_PENDING':
        draft[action.language].isFetching = true;
        break;

      case 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_FULFILLED':
        draft[action.language].isFetching = false;
        draft[action.language].error = false;
        draft[action.language].data = action.data;
        break;

      case 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_REJECTED':
        draft[action.language].isFetching = false;
        draft[action.language].error = {
          status: action.error.status,
          statusText: action.error.statusText,
        };
        break;

      case 'downloadCenter/DOWNLOAD_EDITING_REQUEST_PENDING':
        if (!draft.editing) {
          draft.editing = {};
        }

        draft.editing.isLoading = true;
        break;

      case 'downloadCenter/DOWNLOAD_EDITING_REQUEST_FULFILLED':
        if (draft.editing) {
          draft.editing.isLoading = false;
          draft.editing.items = action.downloads;
        }
        break;

      case 'downloadCenter/DOWNLOAD_EDITING_DELETE':
        if (draft.editing?.items) {
          draft.editing.items = draft.editing.items.filter((i) => i.previewId !== action.previewId);
        }
        break;

      default:
    }
  },
  initialState,
);

export default reducer;
