import styled, { css } from 'styled-components';

export const SecondLevelNavDesktop = styled.div(({ theme }) => {
  return css`
    width: 33.33%;
    background-color: ${theme.colors.grey03};
    padding: 1.5rem 1rem 1.5rem 0;

    ul {
      margin: 0;
    }
  `;
});
