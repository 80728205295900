import styled, { css } from 'styled-components';

// utils
import { media } from '../utils/no-touch-device';
import { StyledSpan } from '../profile/initials.styles';

type IconPosition = 'left' | 'right' | 'both' | 'none';

export const StyledButton = styled.button<{
  selected?: boolean;
  $iconPosition: IconPosition;
  paddingLeft?: boolean;
  highlighted?: boolean;
}>`
  ${({ theme, selected, $iconPosition, paddingLeft, highlighted }) => {
    return css`
      width: 100%;
      min-height: 2.75rem;
      transition: none;
      position: relative;
      display: flex;
      align-items: center;
      font-size: ${theme.fontSizes[2]}px;
      line-height: ${theme.lineHeights[1]}px;
      font-weight: ${theme.fontWeights.medium};
      border: 1px solid transparent;
      padding: 0.6875rem 0.5rem;
      ${$iconPosition === 'left' ? 'padding-left: 0' : ''};
      ${$iconPosition === 'right' ? 'padding-right: 0' : ''};
      ${$iconPosition === 'both' ? 'padding-right: 0; padding-left: 0' : ''};
      ${paddingLeft ? 'padding-left: 2.625rem' : ''};

      && {
        color: ${selected ? theme.colors.primary : theme.colors.black};
        &.selected {
          color: ${theme.colors.primary};
        }
      }

      ${StyledSpan} {
        left: 5px;

        ${media.noTouch`
            left: 10px;
        `}

        .initials& {
          left: 5px;

          ${media.noTouch`
            left: 3px;
        `}
        }
      }

      &.initials {
        padding-right: 0;
        ${media.noTouch`
          padding-left: 2.375rem;
        `}
      }

      .cart-count {
        position: absolute;
        right: 0;
        margin: 0.6875rem;
      }

      ${media.noTouch`
        min-height: 2.5rem;
        padding: 0.5625rem 0.5rem;
        ${$iconPosition === 'left' ? 'padding-left: 0' : ''};
        ${$iconPosition === 'right' ? 'padding-right: 0' : ''};
        ${$iconPosition === 'both' ? 'padding-right: 0; padding-left: 0' : ''};
        ${paddingLeft ? 'padding-left: 3.1875rem; padding-right: 0;' : ''};

        &.initials {
          padding-right: 0;
        }
     `}

      &&:focus-visible {
        border: 1px solid ${theme.colors.primary};
        outline: 1px solid ${theme.colors.primary};
        text-decoration: none;
      }

      &&:visited:focus {
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }

      ${highlighted &&
      `
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      ${media.hover`
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      &:active {
        ${({ theme }) => theme.global.disableTapHighlight}
        text-decoration: none;
        background-color: ${theme.colors.cl15};
        color: ${theme.colors.white};

        .initials {
          border-color: ${theme.colors.white};
        }
      }

      span {
        min-height: unset;

        svg {
          width: 1.25rem;
          fill: currentColor;
        }

        &.first-icon,
        &.last-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 2.75rem;

          ${media.noTouch`
            min-width: 2.5rem;
          `}
        }

        &.last-icon {
          margin-left: auto;
        }
      }
    `;
  }}
`;
