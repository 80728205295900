import React from 'react';
import { Container } from '@geberit/gdds';

// components
import { ProfileFlyout } from './Profile-flyout';

// styles
import { FlyoutWrapper } from '../locator-switch/locator-selector.styles';
import { AnimationLayer } from '../common/common.styles';

// utils
import { useProfilePageOpen, useNavOpen } from '../mega-menu-provider';

export function ProfilePage() {
  const { isProfilePageOpen } = useProfilePageOpen();
  const { setIsNavOpen, isNavOpen } = useNavOpen();

  function closeFlyout() {
    setIsNavOpen(false);
  }

  return (
    <FlyoutWrapper isOpen={isProfilePageOpen}>
      <AnimationLayer
        animationType={isNavOpen ? 'fadeIn' : 'fadeOut'}
        animationDelay={isNavOpen ? 0.3 : 0}
      >
        <Container maxContentWidth="80rem">
          <ProfileFlyout onElementClick={closeFlyout} />
        </Container>
      </AnimationLayer>
    </FlyoutWrapper>
  );
}
