import { useContext } from 'react';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { useTracking as useTracking2 } from 'utils/tracking/track';
// components
import { CmsLink } from '../../../Link/CmsLink';
import { Button, BUTTON_TYPES } from '../../../Button/Button';
import { Icon } from '../../../Icon/Icon';
import { NavigationContext } from '../../NavigationContext';
import ExternalNavLink from './ExternalNavLink';
import { NAVIGATION_RESTRICTIONS } from '../../constants';

// styles
import styles from '../main-navigation.module.scss';

// utils
import { useTracking } from '../../../../utils/hooks/useTracking';
import { navigationTrackingAction } from '../../trackingActions';
import { campusReferenceUrlSelector } from '../../../../utils/selectors/globalsSelectors';
import { useNord } from '../../../../utils/hooks/use-nord';

// types
import type { NavItem } from '../transformers';
import { classNameBuilder } from '../../../../utils/classNameBuilder';

type MainNavigationItemProps = {
  item: NavItem;
  index: number;
  handleClick: (currentItem: any, i: any, isLink: any) => void;
  closeFlyout: (...args: unknown[]) => unknown;
  activeFirstLevel: any[];
  setActiveFirstLevel: React.Dispatch<React.SetStateAction<any[]>>;
  isActive?: boolean;
};

const MainNavigationItem = ({
  item,
  item: { children, url, label },
  index,
  handleClick,
  closeFlyout,
  activeFirstLevel,
  setActiveFirstLevel,
  isActive,
}: MainNavigationItemProps) => {
  const campusReference = useSelector(campusReferenceUrlSelector);
  const track = useTracking();
  const { trackClick } = useTracking2();

  const navigationContext = useContext(NavigationContext);

  const isNord = useNord();
  const isFirstLevel = index === 0;
  const iconClassName = !isFirstLevel ? styles.navigationItemIcon : '';
  let activeClass = '';
  if (isActive) {
    activeClass = isNord ? styles.activeNord : styles.active;
  }

  const trackLinkClick = () => {
    track.trackEvent(navigationTrackingAction(label));
  };

  const buttonClick = (e, isLink) => {
    trackClick({
      click_intent: 'navigation',
      click_text: label,
      click_element: `navigation level ${index + 1}`,
      click_url: url,
    });

    if (
      isFirstLevel &&
      !isEmpty(navigationContext.state.clickPath) &&
      isEqual(activeFirstLevel[0], item)
    ) {
      closeFlyout();
    } else if (isFirstLevel) {
      setActiveFirstLevel([e.target, item]);
      handleClick(item, index, isLink);
    } else {
      handleClick(item, index, isLink);
    }
  };

  const button = (
    <Button
      type={BUTTON_TYPES.PLAIN}
      className={classNameBuilder(iconClassName, activeClass)}
      onClick={buttonClick}
      noTracking
    >
      {label?.substring(0, NAVIGATION_RESTRICTIONS[index + 1])}
      {!isFirstLevel && children && <Icon symbol="chevron-large-right" />}
    </Button>
  );

  if (item.external) {
    return <ExternalNavLink item={item} hasButtonWrapper={false} />;
  }

  if (
    url &&
    ((!isEmpty(children) && index === 3) ||
      isEmpty(children) ||
      (campusReference && url === campusReference))
  ) {
    const link = { target: url, type: 'internal_link' };

    return (
      <CmsLink
        link={link}
        className={activeClass}
        onClick={(e) => buttonClick(e, true)}
        tracking={trackLinkClick}
        isNavigationItem={true}
      >
        {label?.substring(0, NAVIGATION_RESTRICTIONS[index + 2])}
        {!isFirstLevel &&
          index !== 3 &&
          children &&
          (!campusReference || url !== campusReference) && <Icon symbol="chevron-large-right" />}
      </CmsLink>
    );
  }

  return button;
};

export default MainNavigationItem;
